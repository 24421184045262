body{
    font-family: $familynormal;
    color: $color--grey;
}
p{
    font-size: 20px;
    @include tablette-only{
        font-size: 18px;
    }
    @include under-small{
        font-size: 16px;
    }
}

li{
    list-style: none;
    font-size: 16px;
    margin-bottom: 10px;
    @include tablette-only{
        font-size: 14px;
    }
}
h1{
    font-family: $familyheader;
    font-size: 70px;
    @include tablette-only{
        font-size: 40px;
    }
}
.h1--landing{
    font-size: 100px;
    margin-top: 4%;
    margin-bottom: 1.5%;
    line-height: 1;
    letter-spacing: .1em ;
    color: $color--soft;
    @include laptop{
        font-size: 75px;
        margin-bottom: 1%;
    }
    @include tablette-only{
        font-size: 50px;
        margin-bottom: 0;
        margin-top: 2%;
    }
}
.h1--page{
    @include under-small{
        width: 100%;
        text-align: center;
    }
}
h2{
    font-family: $familyheader;
    color: $color--cta;
}
.h2--landing{
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 10%;
    letter-spacing: .1em;
    color: white;
    @include laptop{
        font-size: 30px;
    }
    @include under-small{
        margin-bottom: 5%;
    }
}
.h2--header{
    margin: 0;
    font-size: 20px;
}
h3{
    font-size: 15px;
    font-weight: $regular;
    text-transform: uppercase;
    color: $color--secondary;
    // @include laptop{
    //     font-size: 12px;
    // } 
}
.h3--cta{
    font-size: 20px;
    font-family: $familyheader;
    color: $color--cta;
}
.h3--landing{
    letter-spacing: .2rem;
    margin-bottom: 3%;
    @include laptop{
        font-size: 12px;
    }
    @include under-small{
        width: 53%;
    }
}
h5{
    color: $color--secondary;
    margin: 2% 0;
}
h6{
    margin: 0%;
    color: $color--grey;
    font-size: 10px;
}
span{
    color: white;
}

button{
    font-family: $familynormal;
    text-transform: uppercase;
}