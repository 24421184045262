.filter{
    display: flex;
    border-radius: 50px;
    padding: 10px 50px;
    height: 140px;
    width: 400px;
    & h4{
        margin-top: 0;
        @include under-small{
            font-size: 14px;
        }
    }
    justify-content: space-between;
    @include tablette-only{
        width: 65%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    @include under-small{
        width: 60%;
    }
    // & h4{
    //     color: $color--cta;
    // }
}
.dropdown{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: solid 1px $color--grey;
    padding: 4px 7px;
    border-radius: 5px;
    width: 150px;
    height: fit-content;
    cursor: pointer;
    &:hover, &:focus{
        border: solid 1px $color--cta;
    }
    &--row{
        flex-direction: row;
    }
    &__option:hover{
        color: $color--cta;
    }
}