body{
    box-sizing: border-box;
    margin: 0;
}

.hidden{
    display: none;
}
strong{
    color: $color--cta;
}

a{
    text-decoration: none;
    color: $color--cta--light;
}