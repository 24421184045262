.cardProjects{
    display: flex;
    flex-direction: column;
    width: 340px;
    margin: 3%;
    background-color: $color--primary;
    border-radius: 20px;
    position: relative;
    height: 380px;
    text-decoration: none;
    box-shadow: $color--grey 2px 2px 14px 2px;
    @include tablette-only{
        margin-top: 10%;
    }
    @include under-small{
        height: 400px;
        width: 100%;
    }
    &:hover &__verso{
        display: flex;
    }
    &:hover .icon{
        animation: shadow-smooth 2s ease-in-out 0.3s infinite alternate-reverse both;
    }
    &__cover{
        & img{
            height: 200px;
            width: 340px;
            object-fit: cover;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            @include under-small{
                width: 100%;
            }
        }
    }
    &__description{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        color: $color--grey;
        padding: 0 5%;
        @include under-small{
            width: 80%;
        }
        & p{
            font-size: 16px;
        }
        & h2{
            margin-bottom: 0;
        }

    }
    &__verso{
        position: absolute;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $color--transparent;
        top: 0;
        left: 0;
        height: 380px;
        width: 340px;
        border-radius: 20px;
        z-index: 2;
        animation: flip-in-ver-right 0.2s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        @include under-small{
            height: 400px;
            width: 100%;
        }
    }
    &__cta{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        top: 12%;
    }
}
.tasks{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 42%;
    padding-left: 7%;
    width: 80%;
    border-top: solid 0.7px $color--shine;
    &__list{
        color: $color--grey;
        margin: 0;
        padding: 0%;
        & li{
            font-size: 14px;
        }
    }
    &__task{
        color: $color--grey;
    }
}