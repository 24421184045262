// medias queries
@mixin laptop{
  @media screen and (max-width: map-get($breakpoints, laptop)) {
    @content;
  }
}
@mixin tablette-only {
    @media screen and (max-width: map-get($breakpoints, tablette)) {
      @content;
    }
  }
  
  @mixin under-small {
    @media screen and (max-width: map-get($breakpoints, small-screen)){
      @content;
    }
  }