.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color--primary;
    color: $color--secondary;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 50px;
    @include under-small{
        padding: 2%;
        text-align: center;
    }
}