@keyframes shadow-smooth {
    0%{
        box-shadow: $color--soft 0px 10px 100px 0px;
    }
    100%{
        box-shadow: $color--primary 0px 0px 0px 0px;
    }
}

@keyframes background-smooth--btn {
    0%{
        background-color: $color--soft;
    }
    100%{
        background-color: transparent;
    }
}

@keyframes flip-in-ver-right {
    0% {
      transform: rotateY(-80deg);
      opacity: 0;
    }
    50%{
        transform: rotateY(0);
    }
    80%{
        opacity: 90%;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-in-bottom {
    0% {
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0.01;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes galaxy {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 0%;
    }
  }

  @keyframes galaxy-small {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 50% 25%;
    }
  }

  @keyframes rotate-center {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  //text loading animation
  @-webkit-keyframes loader {
    0% {
      letter-spacing: -0.5em;
      -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
    }
    20% {
      opacity: 0.6;
    }
    40% {
      opacity: 1;
      -webkit-filter: blur(0.00);
            filter: blur(0.00);
    }
  }
  @keyframes loader {
    0% {
      letter-spacing: -0.5em;
      -webkit-filter: blur(12px) opacity(0%);
            filter: blur(12px) opacity(0%);
    }
    20% {
      opacity: 0.6;
    }
    40% {
      opacity: 1;
      -webkit-filter: blur(0.00);
            filter: blur(0.00);
    }
  }
  
  //animation rocket
  @-webkit-keyframes rocket {
    0% {
      -webkit-transform: translate(0, 0) skew(0deg, 0deg);
              transform: translate(0, 0) skew(0deg, 0deg);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
    }
    100% {
      -webkit-transform: translate(1000px, -1000px) skew(-80deg, -10deg);
              transform: translate(1000px, -1000px) skew(-80deg, -10deg);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
  }
  @keyframes rocket {
    0% {
      -webkit-transform: translate(0, 0) skew(0deg, 0deg);
              transform: translate(0, 0) skew(0deg, 0deg);
      -webkit-transform-origin: 50% 50%;
              transform-origin: 50% 50%;
              opacity: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      -webkit-transform: translate(1000px, -1000px) skew(-80deg, -10deg);
              transform: translate(1000px, -1000px) skew(-80deg, -10deg);
      -webkit-transform-origin: 0% 0%;
              transform-origin: 0% 0%;
    }
  }