//colors
$color--primary:#0F1014;
$color--secondary:#BE3E82;
$color--cta:#E43F6F;
$color--soft: #DCEDC8;
$color--active: lighten($color--primary, 40%);
$color--grey:#BBBBBB;
// $color--grey:#BBBBBB;
$color--landing:hsl(228, 14%, 7%, 60%);
$color--transparent: hsl(228, 14%, 7%, 99%);
$color--shine: hsl(88, 51%, 86%, 10%);
$color--cta--light: hsl(343, 75%, 57%, 70%);
//fonts
@import url('https://fonts.googleapis.com/css2?family=Gloock&family=Noto+Sans:wght@100;200;300;400;500;600;700&display=swap');
// $familyheader: 'DM Serif Display', serif;
$familyheader:'Gloock', serif;
$familynormal: 'Noto Sans', sans-serif;
$thin: 100;
$extraLight: 200;
$light: 300;
$regular: 400; //notice: only weight for Header family
$medium:500;
$semiBold: 600;
//shadows
$shadow--cta: $color--cta 0px 10px 100px 0px;
//animation
$anim--icon:shadow-smooth 2s ease-in-out 0.3s infinite alternate-reverse both;