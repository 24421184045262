.gridProjects{
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    animation: fade-in-bottom 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) 0.4s both;
}
.gridContact{
    display: flex;
    width: 90px;
    justify-content: space-between;
    flex-wrap: wrap;
}