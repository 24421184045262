.about {
  display: flex;
  padding: 5% 9%;
  justify-content: space-between;
  @include tablette-only{
    flex-direction: column;
    padding: 1% 0 0 3%;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  &__main {
    display: flex;
    flex-direction: column;
    width: 49%;
    text-align: justify;
    @include tablette-only{
      width: 90%;
      padding-left: 3%;
    }
  }
  &__asside{
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: center;
    align-items: center;
    align-self: center;
    text-align: left;
    
    @include tablette-only{
      background-color: transparent;
      width: 90%;
      flex-direction: column-reverse;
    }
   & div{
    display: flex ;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   } 
   & ul{
    padding: 0;
   }
   & img {
    width: 70%;
    object-fit: fill;
    align-items: center;
    background-color: white;
    align-self: center;
    justify-self: center;
    padding:  5px 5px 25px 5px;
    @include tablette-only{
      padding: 0;
      border-radius: 500px;
      width: 40%;
    }
    
  }
  }
}
