.home{
    display: flex;
    min-height: 100vh;
    background-image: url('../../../assets/back.svg');
    animation: galaxy 30s ease-out infinite alternate-reverse both;
    @include under-small{
        animation: galaxy-small 30s ease-out infinite alternate-reverse both;
    }
    &__main{
        display: flex;
        width: 100vw;
        background-color: hsl(0, 0%, 0%, 70%);
        @include tablette-only{
            flex-direction: column;
        }
    }

    &__visual{
        flex: 1;
        display: flex;
        justify-items: center;
        // background-color: $color--primary;
        background-color: hsl(0, 0%, 0%, 0%);
    }
    &__link{
        display: flex;
        justify-self: center;
        justify-content:center ;
        align-self: center;
        left: 5%;
        right: 5%;
        margin: auto;
        &__img{
            width: 80%;
            animation: shadow-smooth 7s ease-in-out 0s infinite alternate both;
            &:hover{
                animation: none;
                box-shadow: $color--cta 0px 10px 100px 0px;
            }
            @include tablette-only{
                width: 50%;
                margin-bottom: 3%;
            }
        }
    }
    &__contact{
        display: flex;
        position: absolute;
        top: 2%;
        right: 2%;
        @include tablette-only{
            width: 95%;
        }
        @include under-small{
            justify-content: center;
        }
    }
    &--footer{
       position: absolute;
       right: 3%;
       bottom: 10px;
        @include tablette-only{
            display: none;
        }
    }
    &--loading{
        background-image: none;
        background-color: $color--primary;
    }
}
.header__landing{
    flex: 2%;
}