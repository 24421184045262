.header{
    display: flex;
    justify-content: space-between;
    height: 50px;
    background-color: $color--primary;
    align-items: center;
    padding-left: 3%;
    padding-right: 3%;
    left: 0;
    right: 0;
    border-bottom: solid 0.1px;
   
    &__link{
        @include tablette-only{
            position: absolute;
            right: 55%;
            left: 45%;
        // right: 2%;
        // top: 95px;
        }
        // @include under-small{
        //     top: 115px;
        // }
    }
    &__titles{
        & img{
            animation: rotate-center 10s ease-in 1s infinite both;
        }
    }
    &__logo{
        height: 40px;
        // @include under-small{
        //     position: absolute;
        // right: 1%;
        // top: 75px;
        // }
    }
}
.header__landing{
    width: 60%;
    padding:auto;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    padding: 5% 0% 5% 5%;
    @include tablette-only{
        width: 95%;
        padding-top: 8%;
    }
    @include under-small{
        padding-top: 14%;
    }
}